.signinFormInput {
    height: 3rem !important;
    border-radius: 0 !important;
}
.logo-upload-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo-upload-label {
    display: inline-block;
    position: relative;
}

.logo-placeholder {
    display: inline-block;
}
.loginTitleFont{
    font-family: Georgia, 'Times New Roman', Times, serif !important;
}
.navLinksnavbar {
    padding-top: 2rem !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    width: 11rem;
}
.loginButton{
    border-radius: 0 !important;
}

.navbar-nav .nav-link {
    color: #fff;
    white-space: nowrap;
}

.dropstart .dropdown-toggle {
    margin-right: 1rem;
}

.dropEnd .dropdown-toggle {
    margin-right: 1rem;
}

.dropdown-item:hover {
    color: #000;
}

.dropdown .dropdown-menu {
    display: none;
}

.dropdown:hover > .dropdown-menu, .dropstart:hover > .dropdown-menu {
    display: block;
}

.dropdown:hover > .dropdown-menu, .dropEnd:hover > .dropdown-menu {
    display: block;
}

.dropEnd:hover > .dropdown-menu {
    position: absolute;
    top: 0;
    left: 100%;
}

.dropEnd .dropdown-toggle {
    margin-left: 1rem;
}

@media screen and (min-width:769px) {
    .dropstart:hover > .dropdown-menu {
        position: absolute;
        top: 0;
        right: 100%;
    }

    .dropstart .dropdown-toggle {
        margin-right: 1rem;
    }
}

.tech-logo {
    display: inline-block;
    width: 30px; /* Example size, adjust as needed */
    height: 30px; /* Example size, adjust as needed */
    background-size: cover;
    margin-right: 5px; /* Space between logos */
    cursor: pointer;
}
.popup-content-scrollable {
    max-height: 45rem;
    overflow-y: auto;
    padding: 15px;
}
.requestLogo {
    width: 1.2rem;
    height: 1.2rem;
    margin: 0;
    padding: 0;
}

.message-bubble {
    border-radius: 20px;
    padding: 10px;
    margin: 10px 0;
    max-width: 80%;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.tech-logo {
    position: relative;
    display: inline-block;
}

.custom-select-wrapper {
    position: relative;
    margin-top: .6rem;
}

.background-Confirmation-chat {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 8;
}

.custom-select {
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: none !important;
    background-color: transparent !important;
    z-index: 1;
}

.custom-options {
    position: absolute;
    top: 100%;
    right: 0;
    width: 9rem;
    text-align: start;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 2;
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.5;
    box-shadow: 0 8px 16px rgba(0,0,0,0.15);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font-family: Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic, Noto Sans JP, sans-serif;
}

.custom-option {
    padding: 5px;
    cursor: pointer;
}

    .custom-option:hover {
        background-color: #f0f0f0;
    }

.badge {
    position: relative;
    top: 2px;
    right: 6px;
    color: #000;
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    font-size: .7rem !important;
    transform: translate(50%, -50%);
}
.badge1 {
    position: relative;
    bottom: 10px;
    padding-right: 5px !important;
    padding-left: 5px !important;
    padding-top: 3px !important;
    padding-bottom: 3px !important;
    color: #000;
    font-size: .7rem !important;
    transform: translate(50%, -50%);
}

.accordion-button:not(.collapsed) {
    color: #2196F3;
    background-color: #fff;
    box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.updateButton {
    border-radius: 0px !important;
}

.dropdown-task-convo {
    width: 15rem;
    margin-top: 10px;
    border-radius: 5px;
    box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
    padding: 8px;
    border: none;
    top: 100% !important;
    font-size: 14px;
    font-family: Figtree, Roboto, Noto Sans Hebrew, Noto Kufi Arabic, Noto Sans JP, sans-serif;
}

.form-select-taskMess {
    display: block;
    width: 100%;
    padding: 0.375rem 1.25rem 0.375rem 0.45rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: none !important;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
}

.button-taskMess {
    display: block;
    width: 100%;
    padding: 0.375rem 1.25rem 0.375rem 0.45rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    border: none !important;
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none;
}


.confirmation-box-chat {
    position: fixed;
    top: 29rem;
    z-index: 9;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 50rem;
    height: 47rem;
    max-height: 50rem;
    overflow: auto;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}

.react-datepicker-popper[data-placement^=bottom] {
    padding-top: 10px;
    z-index: 9999 !important;
}

.gray-color {
    background-color: #e8e8e8 !important;
}

.date-picker-input-Task-all {
    display: block;
    width: 100%;
    padding: .9rem 0.75rem !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    color: #000000;
    background-color: #ffffff;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid #dee2e6;
}

.custom-dropdown {
    position: relative;
    width: 100%;
    color: #ffffff;
    padding: 14px;
    cursor: pointer;
}

.dropdown-selected {
    background-color: transparent;
}

.dropdown-options {
    position: absolute;
    top: 42px;
    left: -19px;
    margin-top: 10px;
    width: 12rem;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 9999;
}

.dropdown-option {
    width: 8rem;
    border-radius: 5px;
    color: #fff;
    margin-bottom: 10px;
    border: 1px solid transparent;
    background-color: transparent;
}

.smallTag {
    font-size: 10px;
    line-height: 14px;
    text-transform: uppercase;
    padding: 0 4px;
    vertical-align: 1px;
    cursor: pointer;
}

.vinFontSize {
    font-size: 10pt;
    font-weight: 700;
}

.line-item-text {
    font-size: 10pt;
}

.fontSize-10 {
    font-size: 1rem;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
}

.listUl {
    list-style: disc !important;
    display: revert !important;
    margin-left: 5rem;
}

.confirmation-box-deny {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 25rem;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}

.confirmation-box-pick {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 30rem;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}

.fontSize-8 {
    font-size: .9rem;
    cursor: pointer;
    padding: 0px;
    margin: 0px;
}

.inventoryLogo {
    width: 7rem;
    height: 7rem;
    margin: 0;
    padding: 0;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.fancy-or {
    position: relative;
    display: inline-block;
    padding: 0 10px; /* Adjust the padding as needed */
}

.pieJobSource {
    height: 24rem !important;
}

.pieWidth {
    height: 28rem !important;
    width: 75rem !important;
}

.piePayWidth {
    height: 35rem !important;
    width: 100% !important;
}

.pieSize {
}

.pieSizeNon {
    min-height: 20rem !important;
    max-width: 25rem !important;
    max-height: 20rem !important;
}

.yearDropdownWidth {
    min-width: 11.5rem;
}

.fancy-or::before,
.fancy-or::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 20px); /* Adjust the width and padding values */
    height: 1px;
    background-color: #000; /* You can change the color here */
}

.fancy-or::before {
    left: 0;
}

.modal.show .modal-dialog {
    transform: none;
    width: fit-content !important;
    max-width: fit-content !important;
}

.enlarged-image-container {
    width: calc(100% - 20px);
    height: auto;
    overflow: hidden
}

.enlarged-image {
    width: calc(100% - 10px);
    height: auto;
    object-fit: contain;
}

.width {
    min-width: fit-content !important;
    max-width: fit-content !important;
    width: fit-content !important;
}

@media (max-width: 992px) {
    .navbar-collapse.show {
        left: 0;
        height: fit-content;
        width: 100%;
        transition: left 0ms;
        z-index: 99;
        background-color: #0CAFFF;
        margin-top: 1rem;
        padding-top: 1rem;
        padding-left: 2rem;
    }
}
/* CSS for the red "X" and circle */
.image-overlay {
    position: absolute;
    margin: .5rem;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px; /* Adjust the size of the circle */
    height: 30px; /* Adjust the size of the circle */
    background-color: rgba(255, 0, 0, 0.4); /* Red with 70% opacity */
    border-radius: 50%;
    color: white;
    font-weight: bold;
    cursor: pointer;
    z-index: 1; /* Ensure it's above the image */
}

.fancy-or::after {
    right: 0;
}

.calendar-wrapper {
    position: initial; /* Relative positioning for overlay */
}
/* CSS for highlighting selected options */
.selected-option {
    background-color: #0CAFFF; /* Change the background color to your desired highlight color */
    color: white; /* Change the text color for better visibility */
}

.iconDark {
    color: #212529 !important;
}

.calfont {
    margin-top: 5px;
    font-weight: 400;
    font-size: 14px !important;
    line-height: 20px;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.eventTags {
    font-size: 10px;
    color: #FFF;
    padding: 1px 4px;
    border-radius: 3px;
    white-space: nowrap;
    font-weight: 500;
}
/* Adjust the width of calendar cells to show more content */
.rbc-time-view .rbc-time-header-content,
.rbc-time-view .rbc-time-slot {
    min-height: 25px; /* Increase the cell height */
}

.rbc-timeslot-group {
    border: 1px, solid, #cecece;
}

.rbc-time-view {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    width: 100%;
    border: 1px solid #ddd;
    border-left: 1px solid transparent;
    min-height: 0;
}

.popup {
    z-index: 999;
}

.bg-work {
    background-color: #ddd;
    padding: 10px !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
}
.popup-content-work {
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    width: 380px;
    z-index: 999999999;
    position: absolute;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
    background: #FFF;
    transition: all .3s;
}

.popup-content-work-main {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    width: 380px;
    z-index: 999999999;
    position: absolute;
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    background: #FFF;
    transition: all .3s;
}

.popup-content {
    padding: 10px;
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    width: 300px;
    z-index: 999999999;
    position: absolute;
    border-radius: 6px;
    background: #FFF;
    transition: all .3s;
}
/* Add this to your CSS file */
.day-view-popup {
    transform: translateX(-50%);
}

/* Reduce margins and padding for events */
.rbc-time-view .rbc-event {
    margin: 3px 5px; /* Adjust margin */
    padding: 2px; /* Adjust padding */
}

/* Adjust font size for event titles */
.rbc-time-view .rbc-event-content {
    font-size: 1rem; /* Adjust font size */
}

/* Adjust font size and spacing for time labels */
.rbc-time-header .rbc-row td {
    font-size: 14px; /* Adjust font size for time labels */
    padding: 2px 5px; /* Adjust padding for time labels */
}

.row {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.input-container {
    position: relative;
    display: inline-block;
}

.widthFormControlPer {
    padding-right: 30px; /* Space for the '%' sign */
}

.percent-sign {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}

/* TimeSelectComponent.css */
.time-picker-container {
    position: relative;
    border: 1px solid #333;
    margin-top: .5rem !important;
}

.alert-container {
    position: relative;
    z-index: 9999;
}
/* Override default styles for buttons */
.btn-group .btn-selected {
    background-color: #ddd;
    padding: 5px 15px;
    color: #000;
    line-height: 1.5em;
}

.btn-group .btn-unselected {
    background-color: #ececec;
    padding: 5px 15px;
    color: #000;
    line-height: 1.5em;
}

/* Disable hover styles */
.btn-group .btn-selected:hover {
    background-color: #ddd;
    color: #000;
}

.btn-group .btn-unselected:hover {
    background-color: #ececec;
    color: #000;
}

.alert1 {
    position: fixed;
    top: 10%;
    left: 50%;
    background-color: rgba(217,83,79, 0.78) !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);
    padding: 10px;
    z-index: 9999;
    padding: 10px;
    overflow: hidden;
    width: 35rem;
}

.alert2 {
    position: fixed;
    top: 10%;
    left: 50%;
    background-color: rgba(217,83,79, 0.78) !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);
    padding: 10px;
    z-index: 9999;
    padding: 10px;
    overflow: hidden;
    max-width: 30rem;
}

.alert {
    position: fixed;
    top: 10%;
    left: 50%;
    background-color: rgba(174,213,195, 0.8); /* Adjust the RGB values and alpha (0.0 to 1.0) as needed */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);
    padding: 10px;
    z-index: 9999; /* To ensure the alert appears on top of other elements */
    padding: 10px;
    overflow: hidden; /* Hide any content that exceeds the container */
    width: 20rem; /* Adjust the max-width as needed */
}

.alertDanger {
    justify-content: center;
    text-align: center;
    background-color: rgba(220, 53, 69, 0.3); /* Adjust the RGB values and alpha (0.0 to 1.0) as needed */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 10px;
    z-index: 9999; /* To ensure the alert appears on top of other elements */
    padding: 10px;
    overflow: hidden; /* Hide any content that exceeds the container */
    width: 20rem; /* Adjust the max-width as needed */
}

.alertInfo {
    justify-content: center;
    text-align: center;
    background-color: rgba(174,213,195, 0.3); /* Adjust the RGB values and alpha (0.0 to 1.0) as needed */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 10px;
    z-index: 9999; /* To ensure the alert appears on top of other elements */
    padding: 10px;
    overflow: hidden; /* Hide any content that exceeds the container */
    width: 20rem; /* Adjust the max-width as needed */
}

.alertRed {
    position: fixed;
    top: 10%;
    left: 50%;
    background-color: rgba(220, 53, 69, 0.4); /* Adjust the RGB values and alpha (0.0 to 1.0) as needed */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transform: translateX(-50%);
    padding: 10px;
    z-index: 9999; /* To ensure the alert appears on top of other elements */
    padding: 10px;
    overflow: hidden; /* Hide any content that exceeds the container */
    width: 27rem; /* Adjust the max-width as needed */
}

.alert-line-container {
    position: relative;
}

.alert-line {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #28a745; /* Green color for the line */
    animation: countdown 3s linear forwards; /* Animation for the line to disappear slowly */
}

.alert-lineRed {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #DC3545;
    animation: countdown 3s linear forwards; /* Animation for the line to disappear slowly */
}

.alert-closeRed {
    position: fixed;
    transform: translateY(-50%);
    right: 5%;
    top: 50%;
    cursor: pointer;
    color: #DC3545; /* Green color for the X button */
    font-size: 16px;
}

.alert-close {
    position: fixed;
    transform: translateY(-50%);
    right: 5%;
    top: 50%;
    cursor: pointer;
    color: #28a745; /* Green color for the X button */
    font-size: 16px;
}

@keyframes countdown {
    0% {
        width: 100%;
    }

    100% {
        width: 0%;
    }
}

/* Add the necessary CSS styles to keep the form at the bottom */
.tab-content {
    display: flex;
    flex-direction: column;
}

.form-container {
    margin-top: auto;
}

.time-picker-label {
    position: absolute;
    top: 50%;
    left: 1rem;
    transform: translateY(-50%);
    color: #888;
    font-size: 0.8rem;
    transition: 0.2s ease all;
}

.time-picker-select {
    padding: 1rem 1rem 0.5rem; /* Add some padding to create space for the floating label */
}

    /* Move the label up when the select is focused or has a value */
    .time-picker-select:focus + .time-picker-label,
    .time-picker-select:not(:placeholder-shown) + .time-picker-label {
        top: 16%;
        font-size: 0.7rem;
        color: #333;
    }

/* DateSelectComponent.css */
.date-picker-container {
    position: relative;
}

.date-picker-label {
    position: absolute;
    top: 16%;
    left: 1rem;
    transform: translateY(-50%);
    color: #000;
    font-size: 1rem;
    transition: 0.2s ease all;
}

.date-picker-input-Task {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    background-color: #ffffff;
    background-clip: padding-box;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid #dee2e6;
}

.date-picker-input {
    border-radius: 7px;
    padding: 1rem 1rem 0.5rem; /* Add some padding to create space for the floating label */
    width: fit-content;
}

    /* Move the label up when the input is focused or has a value */
    .date-picker-input:focus + .date-picker-label,
    .date-picker-input:not(:placeholder-shown) + .date-picker-label {
        top: 0;
        font-size: 0.7rem;
        color: #333;
    }

.logo {
    height: 4rem !important;
    width: 4rem !important;
}

li.nav-item.dropdown.marginend {
    margin-right: 2.5rem !important;
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
    cursor: pointer;
}

.custom-tabs {
    padding: 0;
    margin: 0;
    width: 100% !important;
    overflow-x: hidden; /* This will hide any content that exceeds the width */
}

    .custom-tabs .nav-tabs {
        border-bottom: 1px solid #000; /* Remove the default border */
        margin: 0px;
        background-color: #f8f9fa;
        padding: 0px;
        color: #000 !important;
        padding-left: 5rem; /* Adjust indentation */
    }

    .custom-tabs .nav-link1 {
        flex: 1;
        text-align: center;
        /* Add any other styling you want for the tabs */
    }

        /* Hide the default rounded corners for the active tab link */
        .custom-tabs .nav-link1.active {
            border-radius: 0;
        }

.container-grid {
    display: flex;
    grid-template-columns: 1fr; /* One column that takes up the full width */
    gap: 0; /* No gap between grid items */
}

.custom-tabs .nav-item {
    position: relative;
    margin-right: 5rem; /* Adjust space between tabs */
    width: 10rem;
    padding: 0;
    margin: 0;
    color: #000 !important;
    text-align: center !important;
}

    .custom-tabs .nav-item::before {
        content: '';
        position: fixed;
        bottom: 0;
        color: #000 !important;
        left: 0;
        height: 2px;
        background-color: transparent; /* Set background color to transparent */
    }

    .custom-tabs .nav-item.active {
        color: #000 !important;
        height: 3px; /* Thicker line for the active tab */
        background-color: transparent; /* Set background color to transparent */
    }

.year-input-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5px;
}
/* Add this to your CSS or style section */
.selected-techs {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 10px;
}

.selected-tech {
    background-color: #f0f0f0;
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
}

.btn-remove-tech {
    font-size: 1rem;
    color: #dc3545;
    margin-left: 10px;
    margin-bottom: 4px;
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

/* CustomerInputWithAutocomplete.css */

.autocomplete-list {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 5;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    width: 100%;
}

    .autocomplete-list li {
        padding: 8px 12px;
        cursor: pointer;
    }

        .autocomplete-list li:hover {
            background-color: #0CAFFF;
        }

.year-highlight {
    border: 1px solid #ccc;
    padding: 2px;
    margin: 2px;
}

.highlighted {
    background-color: yellow;
}

.custom-datalist {
    width: 100%;
}

.bg-header {
    background-color: #0CAFFF;
    color: #fff !important;
}

.navbar-dark, .navbar[data-bs-theme=dark] {
    --bs-navbar-color: #fff !important;
    --bs-navbar-hover-color: #fff !important;
    --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
    --bs-navbar-active-color: #fff;
    --bs-navbar-brand-color: #fff;
    --bs-navbar-brand-hover-color: #fff;
    --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
}

.price-list {
    display: grid;
    grid-template-columns: repeat(5, 1fr) !important;
    list-style: upper-roman;
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    text-decoration: none !important;
}

p.list-group-item {
    margin: 0 !important;
}

a {
    text-decoration: none !important;
}

.price-list li {
    display: flex;
    align-items: center;
}

.dropdown .dropdown-menu {
    z-index: 100 !important;
    padding-right: 10px !important;
    padding-left: 0 !important;
    width: 20rem !important;
}

.dropdown {
    z-index: revert !important;
}

.table {
    --bs-table-bg: none !important;
}

.maxwidth {
    min-width: 100%;
}

.noTextDecoration {
    text-decoration: none !important;
}

.underline {
    text-decoration: none !important;
    color: #000;
}

.fs-6 {
    font-size: .7rem !important;
}

.slotcolor {
    background-color: #f75ffa;
}

.greenproxbg {
    background-color: #00ff00;
}

html {
    position: relative;
    min-height: 100%;
}

.show {
    display: block;
}

.text-price-size {
    font-size: .9rem;
}

.tableSpace {
    padding-top: 150px !important;
}

.card-img-product {
    max-height: 30rem !important;
    border: none !important;
}

li {
    list-style: none;
    color: #000 !important;
    font-weight: normal;
}

.borderingTable th {
    border: #000 solid 1px;
}

.borderingTableNT th {
    border: #000 solid 1px;
    border-top: none !important;
}

.dropdownExtra {
    min-width: 19rem !important;
}

.dropdownExtra1 {
    min-width: 24rem !important;
}

.dropdownExtra2 {
    min-width: 27rem !important;
}

.dropdown .dropdown-menu {
    display: none;
    padding-right: 10px;
    padding-left: 10px;
    width: 11rem !important;
}

.SplitDrop {
    columns: 2 !important;
}

.SplitDrop1 {
    columns: 3 !important;
}

.nav-link1 {
    padding: 0 !important;
    font-size: 1.2rem;
}

.dropstart .dropdown-toggle {
    margin-right: 1rem;
}

.dropEnd .dropdown-toggle {
    margin-right: .5rem;
}

.dropdown-item:hover {
    color: #000;
}

.dropdown-item {
    color: #000;
}

.marginNav {
    margin-top: -35px;
}

.dropdown .dropdown-menu {
    display: none;
}

.dropdown:hover > .dropdown-menu, .dropstart:hover > .dropdown-menu {
    display: block;
}

.dropdown:hover > .dropdown-menu, .dropEnd:hover > .dropdown-menu {
    display: block;
}

.dropEnd:hover > .dropdown-menu {
    position: absolute;
    top: auto;
    left: 100%;
}

.dropEnd .dropdown-toggle {
    margin-left: .5rem;
}

.dropstart:hover > .dropdown-menu {
    position: absolute;
    top: auto;
    right: 100%;
}

.dropstart .dropdown-toggle {
    margin-right: 1rem;
}

.borderSquare {
    border-radius: 0px !important;
    min-height: 1.5rem;
}

.loginCenter {
    display: flex;
    justify-content: center;
}

.color-main {
    background-color: #f0f1f2;
    padding-bottom: 12rem;
    padding-top: 4rem;
}

.center {
    margin-left: auto;
    margin-right: auto;
}

.mobileleft {
    margin-left: 5rem !important;
    position: fixed !important;
}

.borderingLight {
    border: 1px solid #fff !important;
    z-index: 99;
    margin-top: -110px !important;
    background: rgba(240, 240, 240, .8);
    color: #000;
}

.aboutIcons {
    width: 8rem;
    height: 8rem;
    padding: 1rem;
    background: #f8f9fa;
    border-radius: 50%;
}

.text {
    margin-top: 4rem;
    margin-bottom: 0px;
    color: #fff;
    z-index: 5;
}

.centered {
    top: 40%;
    padding-left: 90px;
}

body {
    margin: 0;
    margin-bottom: 10px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media (max-width : 992px) {
    .padding-mobile {
        margin: 0 !important;
    }
}

@media (min-width : 993px) {
    .padding-mobile {
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
}

@media (max-width: 992px) {
    .space {
        margin-top: 0rem;
        margin: 0;
        padding: 0
    }
}

.dropdown {
    z-index: 5;
}

@media (max-width: 992px) {
    .MarginStart {
        margin-left: 7rem;
        margin-right: 6rem;
    }
}

@media (max-width: 992px) {
    .navbar-collapse {
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 5;
        padding-left: 0px;
        padding-right: 15px;
        padding-bottom: 15px;
        margin-top: 16px;
        font-size: 20px;
        width: 45%;
        height: 100%;
    }

        .navbar-collapse.collapsing {
            left: -75%;
            transition: height 0s;
        }

        .navbar-collapse.show {
            left: 0;
            transition: left 0ms;
        }

    .navbar-toggler.collapsed ~ .navbar-collapse {
        transition: left 0ms;
    }
}

@media (max-width: 992px) {
    .navbar-collapse1 {
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 5;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        margin-top: 16px;
        width: 100%;
        height: 100px;
    }
}

@media (max-width: 992px) {
    .navbar-collapse2 {
        position: fixed;
        top: 50px;
        left: 0;
        z-index: 5;
        padding-left: 35px;
        padding-right: 15px;
        padding-bottom: 15px;
        margin-top: 16px;
        width: 100%;
        height: 155px;
    }
}

@media (max-width: 992px) {
    .carouselheight {
        height: 345px !important;
    }
}

@media (min-width: 993px) {
    .carouselheight {
        height: 799px !important;
    }
}

@media (max-width: 992px) {
    .img-thumbnail {
        height: 100px !important;
        width: 100%;
    }
}

@media (max-width: 992px) {
    .padding-top {
        margin-top: 1rem;
    }
}

.font-bold {
    font-weight: bold;
    font-size: 25px;
}

.padding-bottom {
    padding-bottom: 10rem;
}

button#dropdown-basic-button1 {
    width: 150px;
    border-radius: 1px !important;
}

button#dropdown-basic-button2 {
    border-radius: 10px !important;
}

button#dropdown-basic-button {
    border-radius: 0px !important;
}

.display3 {
    font-size: 3rem;
    font-weight: 900;
    line-height: 1.25;
    margin: 0;
    padding: 0;
}

.display2 {
    font-size: 2rem;
    font-weight: 900;
    line-height: 1.25;
    margin: 0;
    padding: 0;
}

.today {
    background-color: #54B4D3;
}

.text-small {
    font-size: 1.5rem;
    color: #fff;
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 10px;
}

.footer {
    background-color: #0CAFFF;
}

footer a {
    color: #fff;
    text-decoration: none !important;
}

    footer a:hover {
        color: #b1b1b1;
        text-decoration: none !important;
    }

.map-responsive {
    overflow: hidden;
    padding-bottom: 20px;
}

.color-white {
    background-color: #fff;
}

.map-responsive iframe {
    left: 0;
    top: 0;
    height: 200px !important;
    width: 400px !important;
}

.table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 0px !important;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.table1 {
    width: 350px !important;
}

.fixed {
    position: relative;
    top: 0;
    max-height: 30rem;
}

.margintop {
    padding-top: 13rem;
}

.margintop1 {
    padding-top: 8rem;
    padding-bottom: 10rem;
}

.marginbottom1 {
    padding-bottom: 8rem;
    margin-bottom: 10rem;
}

.width-bar {
    width: 10rem;
}

.list-group-item {
    border: none;
}

#custom_carousel .item {
    color: #000;
    background-color: #eee;
    padding: 20px 0;
}

#custom_carousel .controls {
    overflow-x: auto;
    overflow-y: hidden;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    text-align: center;
    position: relative;
    background: #ddd
}

    #custom_carousel .controls li {
        display: table-cell;
        width: 1%;
        max-width: 90px
    }

        #custom_carousel .controls li.active {
            background-color: #eee;
            border-top: 3px solid orange;
        }

    #custom_carousel .controls a small {
        overflow: hidden;
        display: block;
        font-size: 10px;
        margin-top: 5px;
        font-weight: bold
    }

.categoryImage {
    height: 20rem;
    width: 100%;
    margin: 0;
    padding: 0;
}

.categoryCard {
    margin-right: 1rem;
    margin-top: 1rem;
    padding: 0;
    display: flex;
    justify-content: center;
    border: none !important;
}

.noSpace {
    --bs-gutter-x: 0;
}

.img-thumbnail {
    height: 100% !important;
}

.zoomOnHover {
    overflow: hidden;
    margin: 0 auto;
}

    .zoomOnHover img {
        transition: 0.5s all ease-in-out;
    }

    .zoomOnHover:hover img {
        transform: scale(1.5);
        /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
    }

.ColFont {
    font-weight: bold;
}

.ColIcon {
    font-size: 18px;
}

/* remove padding at top of editor */
.editor :first-child {
    padding: 0px;
    margin: 0;
}

.editor p {
    padding: 0px;
    margin: 0;
}

.editor h1, .editor h2, .editor h3 {
    padding: 0px;
    margin: 0;
}

.password-input-wrapper {
    position: relative;
}

.eye-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
}

    .eye-icon.visible {
        color: blue; /* You can change the color or add any visual indication when the password is visible */
    }
