.item-col {
    width: 30%; /* You can adjust the width percentage as per your preference */
}
.autocomplete-list1 {
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: 5;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    border-radius: 0 0 4px 4px;
    width: 100%;
}

    .autocomplete-list1 li {
        padding: 8px 12px;
        cursor: pointer;
    }

        .autocomplete-list1 li:hover {
            background-color: #0CAFFF;
        }
.serviceTypeButton {
    font-size: 10px !important;
    margin-bottom: 4px !important;
    padding: 0 8px !important;
    text-transform: uppercase !important;
    border-radius: 10px !important;
    margin-right: 8px !important;
    display: inline-block !important;
}
.addItemButton {
    border-radius: 20px !important;
}
.formWidth {
    width: 8rem !important;
}
.cursor-pointer {
    cursor: pointer;
}
/* Define the hover effect for the custom dropdown items */
.custom-dropdown-item:hover {
    background-color: #f0f0f0 !important; /* Change to the desired hover color */
    color: #333; /* Change to the desired text color */
    /* Add any other styles you want to apply on hover */
}
.percentage-sign {
    position: absolute;
    top: 60%; /* Adjust this value as needed to vertically center the '%' sign */
    right: 10px; /* Adjust this value to control the distance from the input field */
    transform: translateY(-50%); /* Vertically center the '%' sign */
    font-weight: 100; /* Optional: Make the '%' sign bold or style it as desired */
}