.img-fluid {
    max-width: 100%;
    height: 10rem !important;
}
.img-fluid-locksmith {
    max-width: 100%;
    height: 8rem !important;
}
.confirmation-box-req {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 20rem;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha value to control darkness */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure the overlay is on top of other elements */
    color: white; /* Optional: Adjust text color */
}

/* InventoryCheckOutComponent.css */

.inventory-checkout-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
}

.popup {
    background-color: #fff;
    border-radius: 8px;
}
.popup-1 {
    background-color: #fff;
    border-radius: 8px;
    padding: 10px;
}
.popup-message {
    margin-bottom: 15px;
}
.popup-header {
    font-size: 1.5em;
    padding-top: .5rem;
    padding-bottom: .5rem;
    margin-bottom: 8px;
    border-start-start-radius: 8px;
    border-start-end-radius: 8px;
    width: 100%;
    background-color: #ccc;
}
.quantity-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    box-sizing: border-box;
}

.button-container {
    display: flex;
}