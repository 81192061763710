.docCard {
    min-width: inherit !important;
    float: left;
    max-width: 1024px;
    min-width: 1024px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 40px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    border-radius: 1px;
}
.headerCard {
    float: left;
    width: 100%;
    clear: both;
    position: relative;
}
.imgCard {
    padding: 40px 10px 0 10px !important;
    box-sizing: border-box;
    width: 100%;
}
.imgDocCard {
    margin: 20px 40px 0 10px !important;
    width: 308px;
    height: 228px;
}
.estimateTextSize {
    font-size: 20px;
    line-height: 25px;
    margin: 30px 0 20px;
    font-family: "Nunito";
    font-weight: 600 !important;
    color: #3e4b51;
}
.estimateTextSizeBody {
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0 15px 0;
    font-weight: 400;
}
.btnUpload {
    line-height: 1.2em !important;
    padding: 8px 8px !important;
    box-sizing: content-box;
    height: 18px;
    border-radius: 15px !important;
    font-weight: 600 !important;
    box-shadow: none !important;
}
.btnColContent {
    display: flex;
}
.confirmation-box-item112 {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 30rem !important;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}
.deleteWidth {
    position: relative !important;
    left: 0%;
    padding: .5rem;
    width: 100%;
    background-color: #ccc !important;
}

.signature-canvas {
    width: 100%;
    height: 100%;
    cursor: crosshair;
    touch-action: none;
    user-select: none;
}

.signBoxTitle {
    font-weight: 500;
    font-size: .9rem;
}
.signatureCanvasModule {
    height: 150px;
    width: 99%;
    border: 1px solid #cad3d6;
    border-radius: 2px;
    margin: 10px 0px 20px 0px;
}
.signatureCanvasModule11 {
    height: 100px;
    width: 99%;
    border: 1px solid #cad3d6;
    border-radius: 2px;
    margin: 10px 0px 20px 0px;
}