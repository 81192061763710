.background-Confirmation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
}
.dropdownCurser{
    cursor: pointer;
}
.imgIconSM{
    height: 1rem;
    width: 1rem;
}
.optionalText_4 {
    color: blue;
}

.imgIconM {
    height: 1.2rem;
    width: 1.2rem;
}
.confirmation-box-item112 {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 30rem !important;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}
.deleteWidth {
    position: relative !important;
    left: 0%;
    padding: .5rem;
    width: 100%;
    background-color: #808080;
}
.signature-canvas {
    width: 100%;
    height: 100%;
    cursor: crosshair;
    touch-action: none;
    user-select: none;
}
.signBoxTitle {
    font-weight: 500;
    font-size: .9rem;
}
.signatureCanvasModule {
    height: 150px;
    width: 99%;
    border: 1px solid #cad3d6;
    border-radius: 2px;
    margin: 10px 0px 20px 0px;
}
.signatureImage {
    width: 5.5rem;
    height: 3rem;
}
.fontSizeTime {
    font-size: 13px;
    color: #3b4b52;
    vertical-align: middle;
    max-width: 204px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    font-weight: 400;
}
.confirmation-box-estimate {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 30rem;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}
.notesEstimateWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.estimateAddPlus11 {
    color: #6aa8ee;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    overflow-wrap: break-word;
}
.notesText {
    margin-bottom: 10px;
    white-space: break-spaces;
    overflow-wrap: break-word;
}
.depositsWrapper {
    margin-right: 20px;
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
}
.jobSectionSpan {
    box-sizing: border-box;
    color: #3b4c53;
    font-family: "Nunito",sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
}
.addPaymentH4 {
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: 400;
}
.depositImageNoPayment {
    max-width: 160px;
    margin-top: 50px;
    width: 8rem !important;
    height: 8rem !important;
}
.depositModuleNoPayment {
    margin: 0;
    position: relative;
    width: 100%;
    text-align: center;
}
.depositJobButton {
    float: right;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255,255,255,.5);
    color: #404040;
    margin-bottom: 20px;
    line-height: 22px;
    padding-top: 0px;
    font-size: 18px;
    white-space: nowrap;
    font-weight: 600;
    min-height: 22px;
}
.depositTitle {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    color: #3b4c53;
    font-family: "Nunito",sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 5px;
}
.btnColContent {
    display: flex;
}
.btnUpload {
    line-height: 1.2em !important;
    padding: 2px 8px !important;
    box-sizing: content-box;
    height: 18px;
    border-radius: 15px !important;
    font-weight: 600 !important;
    box-shadow: none !important;
}
.depositEditPayment {
    padding: 20px;
    position: relative;
    margin-bottom: 30px;
}
.notesWrapper {
    width: 50%;
    padding: 20px;
    box-sizing: border-box;
    display: inline-block;
}
.notesTitle {
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc;
    box-sizing: border-box;
    color: #3b4c53;
    font-family: "Nunito",sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    margin-bottom: 20px;
}
.notesModuleWrapper {
    overflow-wrap: break-word;
    text-overflow: ellipsis;
}
.notesHRWrapper {
    margin-top: 26px;
    position: relative;
    cursor: pointer;
}
.estimateAddPlus {
    color: #6aa8ee;
    font-size: 13px;
    font-weight: 500;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 51%;
}
.hrNotes1Module {
    background: #ecedee;
    border-top: 0;
    border-radius: 8px;
    height: 8px;
    margin-top: 16px;
    margin-right: 8px;
    display: block;
    border: 0;
    margin: 1em 0;
    padding: 0;
}
.hrNotes2Module {
    background: #ecedee;
    border-top: 0;
    border-radius: 8px;
    width: 50%;
    height: 8px;
    margin-top: 16px;
    margin-right: 8px;
    display: block;
    border: 0;
    margin: 1em 0;
    padding: 0;
}
.addPlus {
    position: absolute;
    bottom: 0;
    right: 5%;
    color: #6aa8ee;
    cursor: pointer;
}
.statusDropDown {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
}
.addPlus1 {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #6aa8ee;
    cursor: pointer;
}
.estimateBoxSecond {
    display: flex;
}

.clientRenderText {
    color: rgba(59,76,83,.6);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
}
.statusBorder {
    border: none !important;
    outline: none;
    background: transparent !important;
}
.estimateSendButton {
    border-radius: 10rem !important;
    width: 7rem;
    line-height: 1rem !important;
}
.fixed-col {
    position: sticky;
    bottom: 0;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 1;
    background-color: white; 
}
.statusDrop {
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    background: none;
    border: none;
    margin-top: -6px;
    box-shadow: none !important;
    outline: 0px !important;
    min-height: 3.04rem;
    font-size: 14px;
}
.borderWhite{
    border: none !important;
}
.btngroupEstimate {
    position: relative;
    flex: 1 1 auto;
    border-radius: 50rem;
    line-height: 1rem !important;
    background-color: transparent;
    border: 1px solid #000;
}
.dropdowntoggleEstimate::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.btngroupEstimate:hover {
    position: relative;
    flex: 1 1 auto;
    line-height: 1rem !important;
    border-radius: 50rem;
    background-color: transparent;
    border: 1px solid #000;
}

.addEstimateText {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: .225px;
    color: #6aa8ee;
    cursor: pointer;
    margin: 0 15px;
}
.estimateHeaderButtons {
    position: relative;
    bottom: 0;
    display: flex;

}
.estimateHeader {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    padding: 20px;
    padding-bottom: 30px;
    line-height: normal;
    background-color: #f7f8f8;
}
.invoiceHeader {
    display: flex;
    margin-top: 50px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 30px;
    line-height: normal;
    background-color: #f7f8f8;
}
.headerEstimateTextTitleLI {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: .225px;
    color: #3b4b52;
    padding: 0;
    margin: 0;
    list-style-type: none;
    cursor: pointer;
    border-bottom: 4px solid #000;
}
.clientTextInvoice {
    font-size: 18px;
    font-weight: 700;
    color: #3b4b52;
}
.statusText2 {
    margin-top: 7px;
    width: 120px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
}

.clientText {
    display: flex;
    justify-content: space-between;
}

.statusText {
    display: flex;
    justify-content: space-between;
    width: 156px;
    border-bottom: 1px solid #ecedee;
    margin-bottom: 10px;
}

.estimateNoTextNum {
    color: rgba(59,76,83,.6);
}

.estimateNoText {
    display: flex;
}

.estimateDetailsBox {
    padding: 22px 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
}

.estimateBoxSecondBox1 {
    margin: 22px 40px;
    position: relative;
    width: 186px;
    box-sizing: border-box;
}

@media only screen and (max-width: 1288px) {
    .estimateBox {
        overflow-y: scroll;
    }
}
@media only screen and (max-width: 1288px) {
    .estimateBox1 {
        overflow-y: scroll;
    }
}
.estimateBox1 {
    background-color: #f7f8f8;
    display: flex;
    height: 153px;
    border: 1px solid #dfe2e3;
}
.estimateBox {
    background-color: #f7f8f8;
    height: fit-content;
    padding: 1rem;
    padding-bottom: 2rem !important;
}

.descriptionBox {
    margin: 0 40px 0 34px;
    width: 188px;
    font-size: 13px;
    font-weight: 500;
    position: relative;
    height: 68%;
}

.descriptionText {
    color: #3b4c53;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 24px;
}

.imageBox {
    position: relative;
}

.hrLines2 {
    display: block;
    border: 0;
    background: #ecedee;
    border-top: 0;
    border-radius: 8px;
    height: 8px;
    width: 65%;
    margin: 1em 0;
    padding: 0;
    margin-top: 16px;
    margin-right: 8px;
}

.hrLines1 {
    display: block;
    border: 0;
    background: #ecedee;
    border-top: 0;
    border-radius: 8px;
    height: 8px;
    margin: 1em 0;
    padding: 0;
    margin-top: 16px;
    margin-right: 8px;
}

.plusLogoImage {
    text-align: center;
    height: 15px;
    width: 15px;
    line-height: 32px;
    margin: auto;
    display: inline-block;
    font: normal normal normal 1em/1 'workizicons';
    color: inherit;
    flex-shrink: 0;
    speak: none;
    text-transform: none;
    cursor: pointer;
    -webkit-font-smoothing: antialiased;
}
.percentage-sign {
    position: absolute;
    top: 60%; /* Adjust this value as needed to vertically center the '%' sign */
    right: 10px; /* Adjust this value to control the distance from the input field */
    transform: translateY(-50%); /* Vertically center the '%' sign */
    font-weight: 100; /* Optional: Make the '%' sign bold or style it as desired */
}

.plusLogo {
    width: 32px;
    height: 32px;
    top: 60px;
    left: 120px;
    position: absolute;
    background: #fad400;
    border-radius: 20px;
    align-items: center;
    display: flex;
    z-index: 1;
}

.plusLogoX {
    width: 32px;
    height: 32px;
    color: #fff;
    top: 60px;
    left: 120px;
    position: absolute;
    background: #dc3545;
    border-radius: 20px;
    align-items: center;
    display: flex;
    z-index: 1;
}

.estimateHRLine {
    height: 110px;
    width: 1px;
    background-color: #cad3d6;
}

.estimateBoxFirst {
    background-color: #fff;
    margin-left: 0;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.imageBackground {
    border-radius: 50%;
    background-color: #3b4b52;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 48px;
    object-fit: cover;
}

.estimateText {
    font-size: 16px;
    padding: 0 20px;
    color: #3e4b51;
    font-weight: bold !important;
}

.fLabel {
    position: relative;
    float: left;
    width: 100%;
}
.sajInput {
    height: 2.65rem !important;
    min-height: 2.15rem !important;
    border: 1px solid #ccc !important;
    border-radius: 2px !important;
    background: #FFF;
    box-shadow: none !important;
    font-size: 16px !important;
}
.inputLabel {
    pointer-events: none;
    position: absolute;
    font-weight: 400;
    left: 1.5rem;
    color: #8c8c8c;
    transition: all .3s ease;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: calc(100% - 20px);
    float: inherit;
    margin: 5px;
    text-align: left;
    zoom: 1;
    padding: 0.1rem 1rem 2rem 0rem !important;
}
.inputLabel::after {
    background-color: transparent !important;
}