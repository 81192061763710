.Tabs-module_tabs {
    border-bottom: 1px solid #C4C4C4;
    margin-top: 20px;
    padding-top: 47px;
    width: 100%;
}
@media only screen and (min-width: 770px) {
    .Tabs-module_tabNav {
        display: flex;
        white-space: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        max-width: 100%;
    }
}

.Tabs-module_tabNav {
    position: absolute;
    top: -23px;
    left: 0px;
    width: 100%;
    min-width: 100%
}

.Tabs-module_tabs {
    cursor: pointer;
    float: left;
    margin: 0px;
    text-align: center;
    padding: 14px 25px;
    font-size: 16px;
    position: relative;
    font-weight: 500;
}

.nav-tabs {
    font-size: 1.2rem !important;
    line-height: 1rem !important;
    display: flex;
    border-right: none !important;
    border-left: none !important;
    border-top: none !important;
    border-bottom: 1px solid #C4C4C4 !important;
    color: #000 !important;
}

    .nav-tabs .nav-item {
        border-right: none !important;
        border-left: none !important;
        border-top: none !important;
        margin-bottom: 0;
        color: #000 !important;
    }

    .nav-tabs .nav-link {
        border-right: none !important;
        border-left: none !important;
        border-top: none !important;
        color: #000 !important;
        border-bottom: 1px solid transparent;
    }

        .nav-tabs .nav-link.active {
            color: #000 !important;
            font-weight: 600 !important;
            border-color: #007bff;
            border-right: none !important;
            border-left: none !important;
            border-top: none !important;
            border-bottom: 3px solid #000 !important;
        }
