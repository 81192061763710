.dropdown-border {
    line-height: 16px;
    padding: 15px;
    cursor: pointer;
    margin: 0;
    border-top: 1px solid #cad3d6 !important;
    opacity: 1 !important;
}
    .dropdown-border:hover {
        background-color: #fafafa;
    }
.dropdown-menu {
    padding-top: 4px !important;
    opacity: 1 !important;
}
.dropdown-item:hover {
    color: #000;
    background-color: transparent !important;
    opacity: 1 !important;
}
.ThumbsupActions {
    font-size: 1.5rem !important;
    padding-bottom: 5px !important;
}
.redBackground {
    background-color: red;
    border: 1px solid red;
}