/* Custom styles for the file input */
.custom-file-input-label {
    cursor: pointer;
    background-color: transparent;
    padding: 5px 5px;
    border-radius: 10px;
    color: #007bff;
    border: 1px solid #007bff;
    transition: background-color 0.3s;
}

    .custom-file-input-label:hover {
        background-color: #007bff;
        color: #fff;
    }

.custom-file-input-label-middle {
    cursor: pointer;
    background-color: transparent;
    padding: 5px 5px;
    border-radius: 10px;
    color: #007bff;
    transition: background-color 0.3s;
}

.dots-large {
    font-size: 2rem !important;
    padding: 0 !important;
}
/* Add these styles to your CSS file */

.email-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh !important;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.email-popup {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    max-width: 80%;
    width: 400px; /* Adjust width as needed */
}

.popup-content {
    /* ... your styles for the popup content */
}
.confirmation-box1 {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 25rem;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}
.confirmation-box-image {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 43rem;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}
.img-file-upload {
    width: 4.5rem !important;
    height: 4.5rem !important;
    padding: 0.25rem;
    background-color: var(--bs-body-bg);
    border: var(--bs-border-width) solid var(--bs-border-color);
    border-radius: var(--bs-border-radius);
    max-width: 100%;
}

/* Add this to your existing CSS or create a new CSS file */
.image-container {
    flex-shrink: 0; /* Prevent the image from shrinking */
    margin-right: 20px; /* Add some space between the image and text */
}

.text-container {
    flex-grow: 1; /* Allow the text container to expand */
}
.file-icon {
    margin: .8rem;
    width: 3rem;
    height: 3rem;
    color: #000;
}
.upload-image {
    margin-top: 6rem;
    width: 5rem;
    height: 5rem;
}