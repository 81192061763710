.param_box {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.with-padding {
    padding: 10px;
}

.param-slider {
    display: inline-block;
    margin-right: 10px;
}

.param-ammount {
    display: inline-block;
    width: 50px;
    text-align: center;
}

.slider-container {
    position: relative;
    width: 250px;
    display: inline-block;
    margin: 0 10px;
}

.slider {
    width: 100%;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    position: relative;
    z-index: 2;
}

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 10px;
        height: 24px;
        background: #ffcc00;
        cursor: pointer;
        position: relative;
        z-index: 3;
        border-radius: 4px;
    }

    .slider::-moz-range-thumb {
        width: 10px;
        height: 24px;
        background: #ffcc00;
        cursor: pointer;
        position: relative;
        z-index: 3;
        border-radius: 4px;
    }

.slider-bar {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 10px;
    background: #ddd;
    z-index: 1;
    transform: translateY(-50%);
}

.slider-labels {
    position: absolute;
    width: 100%;
    top: -25px;
    display: flex;
    justify-content: space-between;
}

.slider-label {
    position: absolute;
    transform: translateX(-50%);
    font-size: 12px;
}

.slider-ticks {
    position: absolute;
    width: 100%;
    top: -10px;
    display: flex;
    justify-content: space-between;
}

.slider-tick {
    position: absolute;
    height: 10px;
    width: 2px;
    background: #000;
    transform: translateX(-50%);
}

.btn-group {
    display: inline-flex;
}

.btn11 {
    padding: 5px 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    background-color: #f0f0f0;
    flex: 1;
    text-align: center;
}

.btn:first-child {
    border-radius: 4px 0 0 4px;
}

.btn:last-child {
    border-radius: 0 4px 4px 0;
}

.btn-selected {
    background-color: #ffcc00;
    color: black;
}

.btn-unselected {
    background-color: #f0f0f0;
    color: black;
}
