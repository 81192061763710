
.card-input {
    border: 1px solid #ccc;
}

    .card-input.invalid-amount,
    .card-input:focus-visible.invalid-amount {
        border-color: red !important;
        outline: none;
    }

.invalid-amount-warning {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

.editPaymentPaymentsBalance {
    font-size: 20px;
    line-height: 25px;
    margin: 10px 0 0px;
    font-family: "Nunito";
    font-weight: 600 !important;
    color: #3e4b51;
}
.paymentModuleDue {
    font-size: 1.8rem;
    font-weight: 500;
    color: #404040;
}
.paymentModuleTotal {
    font-size: 1.2em;
    color: #666;
}
.card-form {
    max-width: 100% !important;
    margin: 0 auto;
}

.form-row {
    margin-bottom: 15px;
    margin-top: 15px;
}

.card-input {
    width: 100%;
    height: 40px;
    padding: 5px 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 10px;
}
.confirmation-box-item1 {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 30rem !important;
    padding: 0px;
    margin: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.6);
}
.confirmation-box-item {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 25rem !important;
    padding: 0px;
    margin: 0px;
    border-radius: 10px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.6);
}
.background-Confirmation1 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 9999;
}
.deleteWidth1 {
    position: relative !important;
    left: 0%;
    min-width: 30rem;
    background-color: #808080;
}