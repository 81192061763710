.row-spinner {
    display: flex;
    align-items: center;
}

.dot {
    width: 20px;
    height: 20px;
    background-color: #007bff;
    border-radius: 50%;
    margin: 0 10px;
    opacity: 0;
    transition: opacity 0.3s;
}

.visible {
    opacity: 1;
}
