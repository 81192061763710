.invoiceHeaderText {
    font-size: 20px;
    line-height: 25px;
    margin: 5px 0 0px;
    font-family: "Nunito";
    font-weight: 600 !important;
    color: #3e4b51;
}
.code-container {
    border: 2px solid #6FBF72;
    border-radius: 10px;
    padding: 10px;
    margin: 20px;
    background-color: rgba(76, 175, 80, 0.2);
}
.isvPaid {
    font-size: 16px;
    border: 1px solid #6ba258;
    color: #6ba258;
    padding: 0px 7px;
    border-radius: 6px;
    margin-left: 10px;
}
.isvUnpaid {
    font-size: 16px;
    border: 1px solid #6ba258;
    color: #6ba258;
    padding: 0px 7px;
    border-radius: 6px;
    margin-left: 10px;
}
.invoiceShowText{
    font-weight: 500;
    font-size: 18pt;
}
.redBackground {
    color: #fff; 
    font-size: 14px;
    background-color: rgba(255, 0, 0, 0.8);
    border: 1px solid #ced4da;
    padding: 5px;
    border-radius: 7px;
}
.greenBackground {
    color: #fff;
    font-size: 1rem;
    font: bold;
    background-color: rgba(76, 175, 80, 0.8);
    border: 1px solid #4CAF50;
    padding: 5px;
    border-radius: 7px;
}
.yellowBackground {
    color: #000;
    font-size: 14px;
    background-color: rgba(299, 195, 91, 0.8);
    border: 1px solid #E5C35B;
    padding: 5px;
    border-radius: 7px;
}
.invoiceText {
    padding: 0px;
    border: none;
    line-height: 16px;
    vertical-align: top;
    font-size: 10pt;
    font-family: 'Noto Sans','Helvetica Neue', sans-serif;
    color: #666;
}
.invoiceTextHeader {
    padding: 0px;
    border: none;
    line-height: 16px;
    vertical-align: top;
    font-size: 12pt;
    font: bold;
    font-family: 'Noto Sans','Helvetica Neue', sans-serif;
    color: #000;
}
.invoicePriceText {
    padding: 0px;
    border: none;
    vertical-align: top;
    font-size: 10pt;
    font-family: 'Noto Sans','Helvetica Neue', sans-serif;
    color: #666;
}
.docCardInvoice {
    min-width: inherit !important;
    float: left;
    max-width: 1024px;
    min-width: 1024px;
    width: 100%;
    margin-left: 1rem;
    padding-bottom: 40px;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    border-radius: 1px;
}
.paymentModuleDue {
    font-size: 2.4rem;
    font-weight: 500;
    color: #404040;
}

.paymentModuleTotal {
    font-size: 1.8em;
    color: #666;
}
.fancy-or-invoice {
    position: relative;
    display: inline-block;
    padding: 0 10px; /* Adjust the padding as needed */
    font-size: 10pt;
    font-family: 'Noto Sans','Helvetica Neue', sans-serif;
    color: #666;
}

.fancy-or-invoice::before,
.fancy-or-invoice::after {
    content: "";
    position: absolute;
    top: 50%;
    width: calc(50% - 30px); /* Adjust the width and padding values */
    height: 1px;
    background-color: #000; /* You can change the color here */
}

    .fancy-or-invoice::before {
        left: 0;
    }

    .fancy-or-invoice::after {
        right: 0;
    }