.coneConfir {
    font-size: 9rem !important;
}

.coneConfirJob {
    font-size: 8rem !important;
}

.form-group {
    display: flex;
    align-items: center;
}

.form-label {
    margin-right: 10px;
}

.dropdown-container {
    position: relative;
    width: fit-content; /* Adjust the width as needed */
}

.dropdown-select-bar {
    width: fit-content;
    padding: 0;
    margin: 0;
    color: #1527f2;
    background-color: transparent; /* Set your preferred background color */
    display: flex;
    justify-content: center;
    cursor: pointer;
    border-radius: 4px;
}



.plus-sign {
    font-size: 28px;
    margin: 0;
    padding: 0;
}

.dropdown-content {
    display: flex;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #fff; /* Set your preferred background color for dropdown content */
    width: 20rem; /* Adjust the width as needed */
    border-radius: 4px;
    padding: .8rem;
    border: 1px solid #000;
    z-index: 9999;
    flex-direction: column;
}

.search-container {
    position: relative;
    display: inline-block;
    margin-bottom: 1rem;
    width: 100%; /* You can adjust the width as per your design */
}

    /* Remove default input styles */
    .search-container input {
        border: none;
        background: none;
        outline: none;
        width: 100%;
        font-size: 16px;
        color: #333; /* Text color */
    }

        .search-container input::placeholder {
            font-size: 24px; /* Adjust the font size as per your preference */
            color: #333; /* Placeholder text color */
            opacity: .4;
        }
    /* Create a line representation */
    .search-container .line {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: #333; /* Line color */
    }

.background-Confirmation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
}

.deleteWidth {
    position: relative !important;
    left: 0%;
    padding: .5rem;
    width: 100%;
    background-color: #808080;
}

.confirmation-box {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 35rem;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}
.confirmation-box-item {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 20rem;
    padding: 0px;
    border-radius: 0px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}
.selected-tags-view {
    color: #fff;
    border: 1px solid #000;
    padding: 6px;
    width: fit-content;
    border-radius: 5px;
    font-size: 12px;
    margin-top: 8px;
    flex: 0 0 auto;
    margin-Right: 5px;
    margin-Bottom: 5px;
    white-space: pre-line;
    cursor: pointer;
}

.dropdown-plus {
    display: flex;
    flex-wrap: wrap;
    align-Items: center;
}

.addPlusTag {
    color: #1527f2;
    cursor: pointer;
}

.jobTagsSelect {
    color: #fff;
    border: 1px solid #000;
    padding: 6px;
    display: inline-block;
    width: fit-content;
    border-radius: 5px;
    margin-top: 8px;
    flex: 0 0 auto;
    margin-Right: 5px;
    margin-Bottom: 5px;
    white-space: break-spaces;
    cursor: pointer;
}

.dropdownJobTags {
    display: inline-block;
}
