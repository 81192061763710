.days-of-week-container {
    display: flex;
    justify-content: space-around; /* This spreads the checkboxes evenly across the available space */
    align-items: center; /* This aligns checkboxes in a straight line vertically */
    flex-wrap: nowrap; /* Prevents wrapping of items to the next line */
    padding: 10px;
    margin: 10px 0;
}

.days-of-week-checkbox {
    margin-right: 10px; /* Space between checkboxes */
}

.confirmation-box-work {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 45rem;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}

.confirmation-box-request {
    position: fixed;
    top: 50%;
    z-index: 9999;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 55rem;
    padding: 0px;
    border-radius: 5px;
    box-shadow: 1px 0px 10px rgba(0, 0, 0, 0.9);
}